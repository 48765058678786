import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import type { FileUploadFile } from "primereact/fileupload";
import { useState } from "react";
import { importReferenceData } from "../../../api-requests/reference-data";
import { appLog } from "../../../context";
import type { ModalDialogProps } from "../../../global-tools/dialog-system";
import { showToast } from "../../../global-tools/toast-system";
import { ClientError } from "../../../helpers/http-client";
import { useRefreshReferencesNReferenceData } from "../../../store/store-hooks";
import OurFileUpload from "../OurFileUpload/OurFileUpload";

export interface UploadFileDialogProps extends ModalDialogProps<void> {
  closeDialog: () => void;
  parameter: UploadReferenceDataDialogParameter;
}

export interface UploadReferenceDataDialogParameter {
  referenceId: number;
}

export default function makeUploadReferenceDataDialog({
  closeDialog,
  parameter: { referenceId },
}: UploadFileDialogProps) {
  const wrapAsync = useWrapAsync();
  const [file, setFile] = useState<FileUploadFile>();
  const refresh = useRefreshReferencesNReferenceData(referenceId);

  const handleReferenceDataImport = wrapAsync(async () => {
    if (!file) return;
    const body = new FormData();
    body.append("file", file);
    try {
      await importReferenceData(referenceId, body);
      refresh();
      closeDialog();
      showToast("Données importées", {
        severity: "success",
      });
    } catch (error) {
      appLog.error(error);
      if (error instanceof ClientError) {
        showToast(error.message, {
          severity: "error",
          sticky: true,
        });
      } else {
        showToast("Echec import données de référence", { severity: "error", sticky: true });
      }
    }
  });

  const content = (
    <div className="m-0 pt-2">
      <OurFileUpload
        onFileChange={(uploadedFile) => setFile(uploadedFile)}
        onFileRemove={() => {}}
      />

      <div className="mt-6 flex justify-content-between">
        <Button className="Btn secondary" label="Annuler" onClick={closeDialog} />
        <Button
          className="Btn primary"
          label="Valider"
          onClick={handleReferenceDataImport}
          disabled={file === undefined}
        />
      </div>
    </div>
  );

  return {
    header: (
      <div className="text-center underline" style={{ fontSize: "22px", fontWeight: "400" }}>
        Uploader un fichier CSV ou Excel
      </div>
    ),
    content,
  };
}
