import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { fetchDatabases } from "../../../api-requests/databases";
import { searchReference } from "../../../api-requests/references";
import { dayJsFormat } from "../../../global-tools/dayjs.helpers";
import type { Reference } from "../../../helpers/format-references";
import { getReferences } from "../../../helpers/get-references";
import { type PaginationOptions, apiRequestWrapper } from "../../../helpers/http-client";
import Navbar from "../../parts/Navbar/Navbar";
import OurDataView from "../../utils/OurDataView/OurDataView";
import SearchInput from "../../utils/SearchInput/SearchInput";
import ReferenceCard from "./ReferenceCard/ReferenceCard";

import "./ReferenceListScreen.scss";

export default function ReferenceListScreen() {
  const wrapAsync = useWrapAsync();
  const [total, setTotal] = useState<number>();
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<Reference[]>();

  const loadData = async (options: {
    limit: number;
    page: number;
  }) => {
    if (searchValue) {
      onSearch();
      return;
    }
    handleReferencesFetch(options);
  };
  const onSearch = wrapAsync(async () => {
    await apiRequestWrapper(async () => {
      const result = await searchReference(searchValue);
      setData(result.data);
      setTotal(result.pagination?.total ?? result.data.length);
    });
  });
  const handleReferencesFetch = wrapAsync(async (options: PaginationOptions) => {
    await apiRequestWrapper(async () => {
      const result = await fetchDatabases(options);
      const formatedData = getReferences(result.data);
      setData(formatedData);
      setTotal(formatedData.length);
    });
  });

  useEffect(
    wrapAsync(async () => {
      await apiRequestWrapper(async () => {
        await loadData({ limit: 200, page: 1 });
      });
    }),
    [],
  );

  return (
    <div>
      <Navbar pageTitle="Liste des référentiels" />

      <div className="ReferenceListScreen Container">
        <div className="Container-content">
          <div className="ReferenceListScreen-header">
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              onSearch={onSearch}
              onClear={() => handleReferencesFetch({ limit: 200, page: 1 })}
            />
          </div>

          <OurDataView<Reference>
            data={data}
            total={total}
            itemTemplate={(reference) => (
              <ReferenceCard
                key={reference.id}
                route={`/references/${reference.id}/create-data`}
                label={reference.name}
                fields={reference.schemaNames.length}
                rows={reference.lineNumber}
                date={dayJsFormat(reference.createdAt, "DD/MM/YYYY")}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
