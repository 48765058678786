import { Dropdown } from "primereact/dropdown";
import type { SelectItem } from "primereact/selectitem";
import SlidersHIcon from "../../icons/SlidersHIcon";

import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { apiRequestWrapper } from "../../../helpers/http-client";
import "./OurDropdown.scss";

export default function OurDropdown<T>({
  selectedOption,
  leftIcon,
  filter,
  placeholder,
  className,
  onChange,
  initialOptions,
  onSearch,
}: {
  selectedOption?: T;
  filter?: boolean;
  leftIcon?: boolean;
  placeholder?: string;
  className?: string;
  onChange?(value: T): void;
  initialOptions?: SelectItem[];
  onSearch?(val: string): Promise<SelectItem[]>;
}) {
  const wrapAsync = useWrapAsync();
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState(false);

  const onFilter = wrapAsync(async (val: string) => {
    if (val === "" || !onSearch) return;
    await handleSearchReference(val);
  });
  const handleSearchReference = async (val: string) => {
    if (!onSearch) return;
    setLoading(true);
    await apiRequestWrapper(async () => {
      const items = await onSearch(val);
      setOptions([...items]);
    });
    setLoading(false);
  };

  useEffect(() => {
    setOptions([...(initialOptions ?? [])]);
  }, []);

  return (
    <div className={`OurDropdown${leftIcon ? " withIcon" : ""}${className ? ` ${className}` : ""}`}>
      {leftIcon && (
        <div className="OurDropdown-icon">
          <SlidersHIcon />
        </div>
      )}
      <Dropdown
        className="OurDropdown-primereactDropdown"
        value={selectedOption}
        onChange={(e) => onChange?.(e.value)}
        options={options}
        checkmark={true}
        highlightOnSelect={false}
        filter={filter}
        onFilter={(ev) => onFilter(ev.filter)}
        placeholder={placeholder}
        emptyMessage="Pas de choix disponibles"
        emptyFilterMessage="Aucun résultat"
        loading={loading}
      />
    </div>
  );
}
