import dayjs from "dayjs";
import { useEffect, useReducer, useState } from "react";
import AuthenticatedAppLoader from "./app-auth/AuthenticatedAppLoader";
import UnauthenticatedAppLoader from "./app-unauth/UnauthenticatedAppLoader";
import ErrorUnexpectedScreen from "./components/screens/ErrorUnexpectedScreen/ErrorUnexpectedScreen";
import { eventBus } from "./global-tools/event-bus";
import { getSessionToken } from "./global-tools/session-storage";
import { hasUrlParameter } from "./global-tools/url-parameters";
import { useAppStore } from "./store/app.store";

export default function AppRoot() {
  const isDesktopMode = window.matchMedia("(min-width: 992px)").matches;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const authenticated = useAppStore((state) => state.isAuthenticated);
  const setAppLayout = useAppStore((state) => state.setAppLayout);
  const hasSessionToken = !!getSessionToken();

  useEffect(() => {
    dayjs.locale("fr");
    eventBus.on("fatalError", setErrorMessage);
    eventBus.on("globalRepaint", forceUpdate);
    return () => {
      eventBus.off("fatalError", setErrorMessage);
      eventBus.off("globalRepaint", forceUpdate);
    };
  }, []);

  useEffect(() => {
    setAppLayout(isDesktopMode ? "desktop" : "mobile");
  }, [isDesktopMode]);

  if (errorMessage) return <ErrorUnexpectedScreen message={errorMessage} />;

  if (!authenticated || hasUrlParameter("token") || !hasSessionToken) {
    return <UnauthenticatedAppLoader />;
  }
  return <AuthenticatedAppLoader />;
}
