import { nbVal, nbValOrUndef } from "@paroi/data-formatters-lib";
import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchDatabase, fetchDatabases } from "../../../api-requests/databases";
import type { Reference } from "../../../helpers/format-references";
import { getReference, getReferences } from "../../../helpers/get-references";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import OurDropdown from "../../utils/OurDropdown/OurDropdown";
import Spinner from "../../utils/Spinner/Spinner";
import CreateReferenceDatum from "./CreateReferenceDatum/CreateReferenceDatum";

import "./ReferenceDataCreateScreen.scss";

export default function ReferenceDataCreateScreen() {
  const { referenceId } = useParams();
  const formatedReferenceId = nbValOrUndef(referenceId);
  const wrapAsync = useWrapAsync();
  const references = useAppStore((state) => state.references);
  const setReferences = useAppStore((state) => state.setReferences);
  const [selectedReferenceId, setSelectedReferenceId] = useState<number>();

  useEffect(
    wrapAsync(async () => {
      if (references === undefined) {
        await apiRequestWrapper(async () => {
          const result = await fetchDatabases({ limit: 200, page: 1 });
          setReferences(getReferences(result.data));
        });
      }
    }),
    [references],
  );
  useEffect(() => {
    if (formatedReferenceId !== undefined) setSelectedReferenceId(formatedReferenceId);
  }, [formatedReferenceId]);

  if (!references) {
    return <Spinner />;
  }
  return (
    <div>
      <Navbar pageTitle="Enregistrement" />

      <div className="ReferenceDataCreateScreen Container">
        <div className="Container-content">
          <div
            className="Card"
            style={{
              display: "flex",
              alignItems: "end",
              gap: "10px",
              textAlign: "left",
              padding: "15px 21px 39px 26px",
            }}
          >
            <label className="Field w-full">
              <span className="Field-label">Sélectionner un référentiel</span>
              <OurDropdown<number>
                selectedOption={selectedReferenceId}
                initialOptions={references.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
                onChange={(e) => setSelectedReferenceId(nbVal(e))}
                filter
                leftIcon
              />
            </label>
          </div>

          <div className="mt-6">
            {selectedReferenceId === undefined ? (
              <NoDataMessage message="Aucun référentiel sélectionné" />
            ) : (
              <LoadForm referenceId={selectedReferenceId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function LoadForm({
  referenceId,
}: {
  referenceId: number;
}) {
  const [reference, setReference] = useState<Reference>();
  const wrapAsync = useWrapAsync();

  useEffect(
    wrapAsync(async () => {
      setReference(undefined);
      await apiRequestWrapper(
        async () => {
          setReference(getReference(await fetchDatabase(referenceId)));
        },
        { handle404: true },
      );
    }),
    [referenceId],
  );

  if (!reference) return <Spinner />;
  return (
    <>
      <Link to={`/references/${reference.id}/data`} className="Btn primary">
        Voir les données
      </Link>
      <CreateReferenceDatum
        className="mt-4"
        referenceId={reference.id}
        referenceSchema={reference.schema}
      />
    </>
  );
}
