import { listVal } from "@paroi/data-formatters-lib";
import {
  type Database,
  type SchemaFieldBase,
  type SchemaFieldTypes,
  formatDatabase,
  formatSchemaTypes,
} from "../helpers/format-databases";
import {
  type ApiRequestResponse,
  type PaginationOptions,
  fetchQuery,
} from "../helpers/http-client";

export async function searchDatabase(query: string): Promise<ApiRequestResponse<Database[]>> {
  const { data, pagination } = await fetchQuery(`references/search?query=${query}`);
  return {
    data: listVal(data, formatDatabase),
    pagination,
  };
}

export async function fetchDatabases({
  limit,
  page,
}: PaginationOptions): Promise<ApiRequestResponse<Database[]>> {
  const { data, pagination } = await fetchQuery(`references?limit=${limit}&page=${page}`);
  return {
    data: listVal(data, formatDatabase),
    pagination,
  };
}

export interface DatabaseFormInput {
  name?: string;
  description?: string;
  schema: SchemaFieldInput[];
}

export interface SchemaFieldInput extends SchemaFieldBase {
  length: number | null;
  default: string | null;
}

export async function createDatabase(body: DatabaseFormInput): Promise<void> {
  await fetchQuery("references", {
    method: "POST",
    body: JSON.stringify(body),
  });
}

export async function fetchDatabase(id: number): Promise<Database> {
  const res = await fetchQuery(`references/${id}`);
  return formatDatabase(res.data);
}

export async function updateDatabase(id: number, body: DatabaseFormInput): Promise<void> {
  await fetchQuery(`references/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  });
}

export async function fetchSchemaFieldTypes(): Promise<SchemaFieldTypes> {
  const res = await fetchQuery("references/schema-types");
  return formatSchemaTypes(res.data);
}

export async function generateDatabase(id: number): Promise<void> {
  await fetchQuery(`references/${id}/generate-database`);
}

export async function deleteDatabase(id: number): Promise<void> {
  await fetchQuery(`references/${id}`, {
    method: "DELETE",
  });
}
