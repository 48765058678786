import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import type { KeyboardEvent } from "react";

import "./SearchInput.scss";

export default function SearchInput({
  value,
  onChange,
  onSearch,
  onClear,
  size,
}: {
  value: string;
  onChange(value: string): void;
  onSearch?(value: string): void;
  onClear?(): void;
  size?: "sm";
}) {
  const onKeydown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key !== "Enter" || value === "") return;
    onSearch?.(value);
  };
  const handleSearchBtn = () => {
    if (value === "") return;
    onSearch?.(value);
  };
  const handleOnClear = () => {
    onChange("");
    onClear?.();
  };

  return (
    <div className={`p-inputgroup SearchInput${size ? " sm" : ""}`}>
      <div className="SearchInput-inputWrapper">
        <InputText
          value={value}
          onChange={(ev) => onChange(ev.target.value)}
          onKeyDown={onKeydown}
          placeholder="Recherche…"
        />
        {value !== "" && (
          <Button
            className="SearchInput-clearBtn"
            style={{ backgroundColor: "white", color: "red" }}
            icon="pi pi-times"
            text
            onClick={handleOnClear}
          />
        )}
      </div>

      <Button
        icon="pi pi-search"
        className="SearchInput-searchBtn p-button-primary"
        onClick={handleSearchBtn}
      />
    </div>
  );
}
