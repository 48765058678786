const availableFilters: { [item: string]: AcceptTypeFilter } = {
  "image/*": (type) => type.startsWith("image/"),
  "image/webp": (type) => type === "image/webp",
  "image/jpeg": (type) => type === "image/jpeg",
  "image/png": (type) => type === "image/png",
  "image/gif": (type) => type === "image/gif",
  "image/svg+xml": (type) => type === "image/svg+xml",
  "application/pdf": (type) => type === "application/pdf",
  "text/csv": (type) => type === "text/csv",
  "application/vnd.ms-excel": (type) => type === "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (type) =>
    type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export interface CreateAcceptTypeFilterOptions {
  refuse?: string | string[];
}

export type AcceptTypeFilter = (type: string) => boolean;

export function createAcceptTypeFilter(
  accept: string | string[],
  options: CreateAcceptTypeFilterOptions = {},
): AcceptTypeFilter {
  const acceptList = typeof accept === "string" ? accept.split(/\s*,\s*/) : accept;
  const acceptFilters = acceptList.map((item) => {
    if (!availableFilters[item]) throw new Error(`invalid accept '${item}'`);
    return availableFilters[item];
  });
  const refuseList =
    typeof options.refuse === "string" ? options.refuse.split(/\s*,\s*/) : options.refuse;
  const refuseFilters = (refuseList ?? []).map((item) => {
    if (!availableFilters[item]) throw new Error(`invalid refuse '${item}'`);
    return availableFilters[item];
  });
  return (type) => {
    for (const filter of refuseFilters) {
      if (filter(type)) return false;
    }
    for (const filter of acceptFilters) {
      if (filter(type)) return true;
    }
    return false;
  };
}
