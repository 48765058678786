import type { SelectItem } from "primereact/selectitem";
import { useEffect } from "react";
import type { SchemaField, SchemaFieldOption } from "../../../helpers/format-databases";
import OurDropdown from "../OurDropdown/OurDropdown";

export default function MultipleChoiceInput({
  value,
  label,
  field,
  options,
  onChange,
}: {
  value?: string;
  label: string;
  options: SchemaFieldOption[];
  field: SchemaField;
  onChange: (value?: string) => void;
}) {
  if (options.length === 0) {
    throw new Error(`enum type field '${field.name}' required but no options provided`);
  }

  useEffect(() => {
    const found = options.find((o) => o.key === value);
    if (value && !found) {
      throw new Error(`enum type field '${field.name}' default value is not valid`);
    }
    if (field.required && !value) onChange(options[0].key);
  }, [value]);

  return (
    <label className="Field">
      <span className="Field-label">
        {label} {field.required && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
      </span>
      <OurDropdown<string>
        selectedOption={value}
        initialOptions={options.map<SelectItem>((o) => ({
          label: o.value,
          value: o.key,
        }))}
        onChange={(e) => onChange(e)}
      />
    </label>
  );
}
