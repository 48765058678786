import { listVal } from "@paroi/data-formatters-lib";
import { type ReferenceDatum, formatReferenceDatum } from "../helpers/format-reference-data";
import {
  type ApiRequestResponse,
  type PaginationOptions,
  fetchQuery,
} from "../helpers/http-client";

export async function searchReferenceData(
  referenceId: number,
  {
    searchTerm,
    options: { limit, page },
  }: {
    searchTerm: string;
    options: PaginationOptions;
  },
): Promise<ApiRequestResponse<ReferenceDatum[]>> {
  const { data, pagination } = await fetchQuery(
    `references/${referenceId}/data/search?query=${searchTerm}&limit=${limit}&page=${page}`,
  );
  return {
    data: listVal(data, formatReferenceDatum),
    pagination,
  };
}

export async function fetchReferenceData({
  referenceId,
  options: { limit, page },
}: {
  referenceId: number;
  options: PaginationOptions;
}): Promise<ApiRequestResponse<ReferenceDatum[]>> {
  const res = await fetchQuery(`references/${referenceId}/data?page=${page}&limit=${limit}`);
  return {
    data: listVal(res.data, formatReferenceDatum),
    pagination: res.pagination,
  };
}

export type ReferenceDatumInput = Map<string, ReferenceDatumInputVal | undefined>;
export type ReferenceDatumInputVal = string | number | boolean | File;

export async function createReferenceDatum(
  referenceId: number,
  body: ReferenceDatumInput | FormData,
) {
  await fetchQuery(`references/${referenceId}/data`, {
    method: "POST",
    body: convertToJsonIfNotFormData(body),
  });
}

export async function importReferenceData(referenceId: number, body: FormData) {
  await fetchQuery(`references/${referenceId}/data/import`, {
    method: "POST",
    body,
  });
}

export async function updateReferenceDatum({
  referenceId,
  datumId,
  body,
}: {
  referenceId: number;
  datumId: number;
  body: ReferenceDatumInput | FormData;
}) {
  await fetchQuery(`references/${referenceId}/data/${datumId}`, {
    method: "PUT",
    body: convertToJsonIfNotFormData(body),
  });
}

export async function deleteReferenceDatum({
  referenceId,
  datumId,
}: {
  referenceId: number;
  datumId: number;
}): Promise<void> {
  await fetchQuery(`references/${referenceId}/data/${datumId}`, {
    method: "DELETE",
  });
}

function convertToJsonIfNotFormData(body: ReferenceDatumInput | FormData): string | FormData {
  if (body instanceof FormData) return body;
  return JSON.stringify(Object.fromEntries(body.entries()));
}
