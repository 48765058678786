import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import type { KpiInputValues } from "../../../../api-requests/settings";
import { type RequestError, fetchStatisticsByReference } from "../../../../api-requests/statistics";
import { appLog } from "../../../../context";
import { checkIsRequestError } from "../../../../helpers/check-schema-field-type";
import type { SchemaField } from "../../../../helpers/format-databases";
import type { Reference } from "../../../../helpers/format-references";
import type { Statistics } from "../../../../helpers/format-statistics";
import { getSeq } from "../../../../helpers/get-seq";
import OurDropdown from "../../../utils/OurDropdown/OurDropdown";
import DefineKpiContent from "./DefineKpiContent/DefineKpiContent";

import "./DefineKpi.scss";

const numberOfKpiCards = 4;

export interface KpiInputValuesExtended extends KpiInputValues {
  error?: string;
  id: string;
}

export default function DefineKpi({
  className,
  references,
}: { className?: string; references: Reference[] }) {
  const wrapAsync = useWrapAsync();
  const [selectedReferenceId, setSelectedReferenceId] = useState<number>();
  const [selectedReference, setSelectedReference] = useState<Reference>();
  const [kpiInputValuesExtended, setKpiInputValuesExtended] = useState<KpiInputValuesExtended[]>();
  const [kpiSchemaFields, setKpiSchemaFields] = useState<SchemaField[]>();
  const [statistics, setStatistics] = useState<Statistics | RequestError>();

  const onSelectedReferenceChange = (value: number) => {
    setSelectedReferenceId(value);
    setKpiInputValuesExtended(undefined);
    setKpiSchemaFields(undefined);
    setStatistics(undefined);
  };
  const refreshKpiInputValues = () => {
    if (selectedReferenceId === undefined || statistics === undefined) return;
    const foundReference = references.find((r) => r.id === selectedReferenceId);
    if (!foundReference) return;
    const validSchemaFields = foundReference.schema.filter((f) => f.type !== "file");
    if (validSchemaFields.length === 0) return;
    setSelectedReference(foundReference);
    setKpiSchemaFields([...validSchemaFields]);
    let kpis: KpiInputValuesExtended[];
    if (!checkIsRequestError(statistics) && statistics.kpis.length > 0) {
      kpis = statistics.kpis.slice(0, 4).map((k) => ({
        name: k.name ?? null,
        property: k.property,
        value: k.value ?? null,
        id: `${k.property}${getSeq()}`,
      }));
      if (kpis.length < numberOfKpiCards) {
        const remains = numberOfKpiCards - kpis.length;
        kpis.push(...addKpis(remains, validSchemaFields));
      }
    } else {
      kpis = addKpis(numberOfKpiCards, validSchemaFields);
    }

    setKpiInputValuesExtended([...kpis]);
  };

  useEffect(
    wrapAsync(async () => {
      if (selectedReferenceId === undefined) return;
      try {
        setStatistics(await fetchStatisticsByReference(selectedReferenceId));
      } catch (error) {
        appLog.error(error);
        setStatistics({
          error: "An error occurs when trying to fetch statistics",
        });
      }
    }),
    [selectedReferenceId],
  );
  useEffect(refreshKpiInputValues, [selectedReferenceId, statistics]);

  return (
    <div className={`Card DefineKpi${className ? ` ${className}` : ""}`}>
      <h2 className="DefineKpi-title">Définir les KPI</h2>

      <label className="Field w-full">
        <span className="Field-label">Sélectionner un référentiel</span>
        <OurDropdown<number>
          selectedOption={selectedReferenceId}
          initialOptions={references.map((d) => ({
            label: d.name,
            value: d.id,
          }))}
          onChange={onSelectedReferenceChange}
          filter
          leftIcon
        />
      </label>

      <DefineKpiContent
        selectedReference={selectedReference}
        kpiInputValuesExtended={kpiInputValuesExtended}
        kpiSchemaFields={kpiSchemaFields}
        refreshKpiInputValues={refreshKpiInputValues}
        onKpiInputValuesChange={(kpisInputValues) =>
          setKpiInputValuesExtended([...kpisInputValues])
        }
        onSuccess={() => setStatistics(undefined)}
      />
    </div>
  );
}

function addKpis(remains: number, validSchemaFields: SchemaField[]): KpiInputValuesExtended[] {
  // This is to prevent infinite loop in while
  if (validSchemaFields.length === 0) {
    throw new Error("empty valid schema fields");
  }
  let n = remains;
  const kpis: KpiInputValuesExtended[] = [];
  while (n > 0) {
    for (const vField of validSchemaFields) {
      if (n === 0) break;
      kpis.push({
        name: vField.label,
        property: vField.name,
        value: null,
        id: `${vField.name}${getSeq()}`,
      });
      --n;
    }
  }
  return kpis;
}
