import { InputText } from "primereact/inputtext";
import type { SchemaField } from "../../../../../../helpers/format-databases";
import OurDropdown from "../../../../../utils/OurDropdown/OurDropdown";

import "./DefineChartKPI.scss";

export default function DefineChartKPI({
  name,
  property,
  kpiSchemaFields,
  onNameChange,
  onPropertyChange,
}: {
  name: string;
  property?: string;
  kpiSchemaFields: SchemaField[];
  onNameChange(value: string): void;
  onPropertyChange(value: string): void;
}) {
  return (
    <div className="DefineChartKPI">
      <h3 className="DefineChartKPI-title mt-6">Définir KPI du diagramme</h3>

      <div className="DefineChartKPI-content mt-2">
        <label className="Field w-full">
          <span className="Field-label">Nom du KPI du diagramme</span>
          <InputText
            className="Field-input w-full"
            placeholder="Champ requis *"
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
            required
          />
        </label>

        <label className="Field w-full">
          <span className="Field-label">Sélectionner un champ</span>
          <OurDropdown<string>
            selectedOption={property}
            initialOptions={kpiSchemaFields.map((k) => ({
              label: k.label,
              value: k.name,
            }))}
            onChange={(value) => {
              onPropertyChange(value);
            }}
            filter
          />
        </label>
      </div>
    </div>
  );
}
