import { useWrapAsync } from "@paroicms/front-app-log";
import type { DataTablePageEvent, DataTableSortMeta } from "primereact/datatable";
import { DataView as PrimeRDataView } from "primereact/dataview";
import { useEffect, useState } from "react";
import NoDataMessage from "../NoDataMessage/NoDataMessage";
import Spinner from "../Spinner/Spinner";

import "./OurDataView.scss";

interface LazyTableState {
  first: number;
  rows: number;
  page: number;
  multiSortMeta?: DataTableSortMeta[];
}

export default function OurDataView<T>({
  data,
  total,
  itemTemplate,
  loadData,
}: {
  data?: T[];
  total?: number;
  itemTemplate(item: T): JSX.Element;
  loadData?(options: { limit: number; page: number }): Promise<void>;
}) {
  const limit = 10;
  const wrapAsync = useWrapAsync();
  const [loading, setLoading] = useState(false);
  const [lazyState, setLazyState] = useState<LazyTableState>({
    first: 0,
    rows: limit,
    page: 1,
  });

  const onPage = ({ first, rows, page, multiSortMeta }: DataTablePageEvent) => {
    setLazyState({
      first,
      rows,
      page: page ? page + 1 : 1,
      multiSortMeta: multiSortMeta ?? [],
    });
  };
  const loadLazyData = async () => {
    if (!loadData) return;
    setLoading(true);
    await loadData({
      limit,
      page: lazyState.page,
    });
    setLoading(false);
  };

  useEffect(() => {
    wrapAsync(loadLazyData)();
  }, [lazyState]);

  const listTemplate = (items?: T[]) => {
    if (!items || items.length === 0) return <NoDataMessage />;
    const list = items.map((i) => itemTemplate(i));
    return <div className="OurDataView-cardList">{list}</div>;
  };

  return data === undefined ? (
    <Spinner />
  ) : (
    <PrimeRDataView
      className="OurDataView"
      value={data}
      listTemplate={listTemplate}
      paginator={data.length > 0}
      lazy={!!loadData}
      onPage={loadData ? onPage : undefined}
      loading={loadData ? loading : undefined}
      first={lazyState.first}
      rows={limit}
      totalRecords={total}
      emptyMessage="Aucune donnée"
      paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink"
      currentPageReportTemplate="{first}-{last} sur {totalRecords}"
    />
  );
}
