import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { fetchSettings, updateDefaultReference } from "../../../../api-requests/settings";
import { appLog } from "../../../../context";
import { showToast } from "../../../../global-tools/toast-system";
import type { Database } from "../../../../helpers/format-databases";
import { apiRequestWrapper } from "../../../../helpers/http-client";
import { useAppStore } from "../../../../store/app.store";
import OurDropdown from "../../../utils/OurDropdown/OurDropdown";
import Spinner from "../../../utils/Spinner/Spinner";

import "./DefineDefaultReference.scss";

export default function DefineDefaultReference({ references }: { references: Database[] }) {
  const wrapAsync = useWrapAsync();
  const [loading, setLoading] = useState(false);
  const settings = useAppStore((state) => state.settings);
  const setSettings = useAppStore((state) => state.setSettings);
  const [selectedDatabase, setSelectedDatabase] = useState<number>();

  const changeDefaultReference = wrapAsync(async () => {
    if (selectedDatabase === undefined) return;
    setLoading(true);
    try {
      await updateDefaultReference(selectedDatabase);
      setSettings(undefined);
      showToast("Définition référentiel réussie", { severity: "success" });
      setSelectedDatabase(undefined);
    } catch (error) {
      appLog.error(error);
      showToast("Echec définition référentiel par défaut", { severity: "error", sticky: true });
    }
    setLoading(false);
  });

  useEffect(
    wrapAsync(async () => {
      if (settings) return;
      await apiRequestWrapper(
        async () => {
          setSettings(await fetchSettings());
        },
        { handle404: true },
      );
    }),
    [settings],
  );

  if (!settings) return <Spinner />;
  return (
    <div className="Card DefineDefaultReference">
      <h2 className="DefineDefaultReference-title">Définir le référentiel par défaut</h2>
      <div className="DefineDefaultReference-content">
        <div className="DefineDefaultReference-reference">
          <span className="DefineDefaultReference-referenceLabel">Référentiel actif</span>
          <span className="DefineDefaultReference-referenceValue">
            {settings.defaultReference
              ? settings.defaultReference.name
              : "Aucun référentiel par défaut défini"}
          </span>
        </div>

        <div>
          <label className="Field">
            <span className="Field-label">Sélectionner un référentiel</span>
            <OurDropdown<number>
              selectedOption={selectedDatabase}
              initialOptions={references.map((d) => ({
                label: d.name,
                value: d.id,
              }))}
              onChange={(value) => {
                setSelectedDatabase(value);
              }}
              filter
            />
          </label>

          <Button
            className="Btn success mt-3"
            label="Valider"
            onClick={changeDefaultReference}
            disabled={selectedDatabase === undefined}
            loading={loading}
          />
        </div>

        <div
          style={{
            color: "#ADADAD",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "justify",
          }}
        >
          Cette section permet aux administrateurs de configurer le référentiel par défaut pour
          l'application. Le référentiel par défaut est utilisé comme base de données principale pour
          toutes les opérations courantes
        </div>
      </div>
    </div>
  );
}
