import { listVal } from "@paroi/data-formatters-lib";
import { formatDatabase } from "../helpers/format-databases";
import type { Reference } from "../helpers/format-references";
import { getReferences } from "../helpers/get-references";
import { type ApiRequestResponse, fetchQuery } from "../helpers/http-client";

export async function searchReference(query: string): Promise<ApiRequestResponse<Reference[]>> {
  const { data, pagination } = await fetchQuery(
    `references/search?query=${query}&status=validated`,
  );
  const references = getReferences(listVal(data, formatDatabase));
  return {
    data: references,
    pagination,
  };
}
