import { nbVal, strVal } from "@paroi/data-formatters-lib";
import { initialFetchAuthUser } from "../api-requests/users";

const localStorageKey = "sess";
export let globalSession: UserSession | undefined;

export interface UserSession {
  id: number;
  token: string;
}

export async function verifyUserSession(): Promise<UserSession | undefined> {
  const raw = localStorage.getItem(localStorageKey);
  if (!raw) return;
  const stored = formatUserSession(JSON.parse(raw));
  if (!stored) return;
  try {
    const result = await initialFetchAuthUser(stored.token);
    const newUserSession = { id: result.id, token: stored.token };
    globalSession = newUserSession;
    return newUserSession;
  } catch (error) {
    forgetSession();
  }
}

export function keepSession(newSession: UserSession) {
  globalSession = newSession;
  localStorage.setItem(localStorageKey, JSON.stringify(newSession));
}

export function getSessionToken(): string | undefined {
  const session = localStorage.getItem(localStorageKey);
  if (session && globalSession?.token) {
    return globalSession.token;
  }
  return;
}

export function forgetSession() {
  localStorage.removeItem(localStorageKey);
  globalSession = undefined;
}

function formatUserSession(payload: any): UserSession {
  return {
    id: nbVal(payload.id),
    token: strVal(payload.token),
  };
}
