import { nbVal } from "@paroi/data-formatters-lib";
import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  type DatabaseFormInput,
  fetchDatabase,
  updateDatabase,
} from "../../../api-requests/databases";
import type { Database } from "../../../helpers/format-databases";
import { apiRequestWrapper } from "../../../helpers/http-client";
import Navbar from "../../parts/Navbar/Navbar";
import Spinner from "../../utils/Spinner/Spinner";
import DatabaseForm from "../DatabaseForm/DatabaseForm";

export default function DatabaseEditScreen() {
  const { databaseId } = useParams();
  const formatedDatabaseId = nbVal(databaseId);
  const navigate = useNavigate();
  const wrapAsync = useWrapAsync();
  const [database, setDatabase] = useState<Database>();

  const handleDatabaseEdition = async (values: DatabaseFormInput): Promise<void> => {
    await updateDatabase(formatedDatabaseId, values);
  };

  useEffect(
    wrapAsync(async () => {
      await apiRequestWrapper(
        async () => {
          setDatabase(await fetchDatabase(formatedDatabaseId));
        },
        { handle404: true },
      );
    }),
    [],
  );

  if (!database) return <Spinner />;
  return (
    <div>
      <Navbar pageTitle={`Base de données: ${database.name}`} />

      <div className="DatabaseCreateScreen Container">
        <div className="Container-content">
          <DatabaseForm
            save={handleDatabaseEdition}
            saveButtonText="Editer la base de données"
            initialValues={{
              name: database.name,
              description: database.description,
              schemaFields: database.schema,
            }}
            successMessage="Base de données editée"
            errorMessage="Echec mise à jour base de données"
            onSave={() => navigate(`/databases/${formatedDatabaseId}/view`)}
          />
        </div>
      </div>
    </div>
  );
}
