import { nbVal, strValOrUndef } from "@paroi/data-formatters-lib";
import { BACKEND_API_URL, appLog } from "../context";
import { eventBus } from "../global-tools/event-bus";
import { forgetSession, getSessionToken } from "../global-tools/session-storage";

export interface PaginationOptions {
  limit: number;
  page: number;
}

export class ClientError extends Error {
  statusCode: number;

  constructor(statusCode: number, message: string) {
    super(message);
    this.name = "ClientError";
    this.statusCode = statusCode;
  }
}

export interface FetchQueryResponse<T> {
  data: T;
  pagination?: Pagination;
}

export async function fetchQuery<T>(resource: string): Promise<FetchQueryResponse<T>>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    token: string;
  },
): Promise<FetchQueryResponse<T>>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    body?: string | FormData;
    method: "DELETE";
  },
): Promise<FetchQueryResponse<T> | undefined>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    body: string | FormData;
    method: "POST" | "PUT" | "PATCH";
  },
): Promise<FetchQueryResponse<T> | undefined>;
export async function fetchQuery<T>(
  resource: string,
  options: {
    token?: string;
    body?: string | FormData;
    method?: "POST" | "PUT" | "PATCH" | "DELETE";
  } = {},
): Promise<FetchQueryResponse<T> | undefined> {
  let token = options.token;
  if (!token) token = getSessionToken();
  if (!token) {
    forgetSession();
    window.location.href = "/";
    return;
  }

  const headers: HeadersInit = {
    Authorization: token ? `Bearer ${token}` : "",
  };
  if (!(options.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const response = await fetch(`${BACKEND_API_URL}/${resource}`, {
    method: options.method ?? "GET",
    headers,
    body: options.body,
  });
  if (response.status < 200 || response.status >= 300) {
    const remoteMessage = await response.text();
    if (response.status >= 400 && response.status < 500) {
      if (response.status === 429) {
        window.location.href = `${window.location.pathname}`;
        return;
      }
      if (!options.token && response.status === 401) {
        forgetSession();
        window.location.href = "/";
        return;
      }
      const messages = getClientErrorMessages(remoteMessage);
      throw new ClientError(
        response.status,
        messages.length === 0 ? "Une erreur est survenue" : messages.join("\n"),
      );
    }
    throw new Error(`Error '${response.status}' from the server: ${remoteMessage}`);
  }

  try {
    const res = await response.json();
    let data = res.data;
    if (!data && res.message) {
      data = {
        message: res.message,
        success: res.success,
      };
    } else if (!data && res.kpis) {
      data = {
        kpis: res.kpis,
        histogram_data: res.histogram_data,
      };
    }

    return {
      data,
      pagination: res.meta ? formatPagination(res.meta) : undefined,
    };
  } catch (error) {
    appLog.error("No json reponse body");
    if (options?.method) {
      return;
    }
    throw new Error("No json reponse body");
  }
}

function getClientErrorMessages(remoteMessage: string): string[] {
  const messages: string[] = [];
  const res = JSON.parse(remoteMessage);
  const errors = res?.errors;
  if (typeof errors !== "object") {
    messages.push(strValOrUndef(res?.message) ?? "Une erreur est survenue");
  } else {
    for (const [, val] of Object.entries(errors)) {
      if (!Array.isArray(val)) {
        const msg = strValOrUndef(val);
        if (msg) messages.push(msg);
        continue;
      }
      messages.push(val.join("\n"));
    }
  }
  return messages;
}

export interface ApiRequestResponse<T> {
  data: T;
  pagination?: Pagination;
}

export interface Pagination {
  perPage: number;
  total: number;
}

function formatPagination(data: any): Pagination {
  return {
    perPage: nbVal(data.per_page),
    total: nbVal(data.total),
  };
}

export async function apiRequestWrapper(
  request: () => Promise<void>,
  { handle404 }: { handle404?: boolean } = {},
): Promise<void> {
  try {
    await request();
  } catch (error) {
    appLog.error(error);
    if (handle404 && contains404(error)) {
      eventBus.emit("404");
      return;
    }
    eventBus.emit("fatalError", "Unexpected fetch error");
  }
}

export function contains404(error: unknown): boolean {
  if (!error) return false;
  if (typeof error === "string") return error.includes("404");
  if (typeof error === "object") {
    const err = error as any;
    return err?.message.includes("404");
  }
  return false;
}

export interface FetchFileInput {
  url: string;
  headers?: HeadersInit;
}

export async function fetchFile({
  headers,
  url,
}: FetchFileInput): Promise<{ blob: Blob; responseHeaders: Headers }> {
  const response = await fetch(url, {
    method: "GET",
    headers,
  });

  if (response.status < 200 || response.status >= 300) {
    const remoteMessage = await response.text();
    throw new Error(`Error '${response.status}' from the server: ${remoteMessage}`);
  }

  return {
    blob: await response.blob(),
    responseHeaders: response.headers,
  };
}
