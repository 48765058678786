import {
  boolVal,
  listValOrUndef,
  nbVal,
  nbValOrUndef,
  strVal,
  strValOrUndef,
} from "@paroi/data-formatters-lib";

export interface Database {
  id: number;
  code: string;
  name: string;
  description?: string;
  organization: Organization;
  schema?: SchemaField[];
  databaseGenerated: boolean;
  createdAt: string;
  schemaNames: string[];
  lineNumber: number;
}

export interface Organization {
  id: number;
  acronym: string;
  name: string;
  enabled: boolean;
  logo?: string;
  createdAt: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface SchemaField extends SchemaFieldBase {
  length?: number;
  default?: string;
}

export interface SchemaFieldBase {
  label: string;
  name: string;
  type: string;
  required: boolean;
  options?: SchemaFieldOption[];
}

export interface SchemaFieldOption {
  key: string;
  value: string;
}

export type SchemaFieldTypes = Map<string, string>;

export function formatSchemaTypes(data: any): SchemaFieldTypes {
  if (typeof data !== "object") {
    throw new Error(`Invalid schemaTypes type '${typeof data}'`);
  }

  const schemaTypesMap: SchemaFieldTypes = new Map();
  for (const [key, val] of Object.entries(data)) {
    const oldVal = schemaTypesMap.get(key);
    if (!oldVal) schemaTypesMap.set(key, strVal(val));
  }

  return schemaTypesMap;
}

export function formatDatabase(data: any): Database {
  const schema = listValOrUndef(data.schema, formatSchema);
  // TODO: Perhaps change schema array to Map so you can check it and set it to use in whole app
  const fieldNames: Set<string> = new Set();
  for (const field of schema ?? []) {
    if (fieldNames.has(field.name)) {
      throw new Error(`Duplicated Schema field name '${field.name}'`);
    }
    fieldNames.add(field.name);
  }
  return {
    id: nbVal(data.id),
    code: strVal(data.code),
    description: strValOrUndef(data.description),
    name: strVal(data.name),
    databaseGenerated: boolVal(data.database_generated),
    createdAt: strVal(data.created_at),
    organization: formatOrganization(data.organization),
    schemaNames: Array.from(fieldNames),
    schema,
    lineNumber: nbVal(data.line_number),
  };
}

function formatSchema(data: any): SchemaField {
  return {
    name: strVal(data.name),
    label: strVal(data.label),
    required: boolVal(data.required),
    type: strVal(data.type),
    default: strValOrUndef(data.default),
    length: nbValOrUndef(data.length),
    options: listValOrUndef(data.options, formatSchemaOptions),
  };
}

export function formatOrganization(data: any): Organization {
  return {
    id: nbVal(data.id),
    name: strVal(data.name),
    acronym: strVal(data.acronym),
    logo: strValOrUndef(data.logo),
    enabled: boolVal(data.enabled),
    createdAt: strVal(data.created_at),
    updatedAt: strValOrUndef(data.updated_at),
    deletedAt: strValOrUndef(data.deleted_at),
  };
}

function formatSchemaOptions(data: any): {
  key: string;
  value: string;
} {
  return {
    key: strVal(data.key),
    value: strVal(data.value),
  };
}
