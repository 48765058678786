import { useNavigate } from "react-router-dom";
import { type DatabaseFormInput, createDatabase } from "../../../api-requests/databases";
import Navbar from "../../parts/Navbar/Navbar";
import DatabaseForm from "../DatabaseForm/DatabaseForm";
import "./DatabaseCreateScreen.scss";

export default function DatabaseCreateScreen() {
  const navigate = useNavigate();

  const handleDatabaseCreation = async (values: DatabaseFormInput): Promise<void> => {
    await createDatabase(values);
  };

  return (
    <div>
      <Navbar pageTitle="Créer une base de données" />

      <div className="DatabaseCreateScreen Container">
        <div className="Container-content">
          <DatabaseForm
            save={handleDatabaseCreation}
            saveButtonText="Enregistrer la base de données"
            successMessage="Base de données enregistrée"
            errorMessage="Echec création base de données"
            onSave={() => navigate("/databases")}
          />
        </div>
      </div>
    </div>
  );
}
