import { useWrapAsync } from "@paroicms/front-app-log";
import { useState } from "react";
import { Link } from "react-router-dom";
import { fetchDatabases, searchDatabase } from "../../../api-requests/databases";
import { dayJsFormat } from "../../../global-tools/dayjs.helpers";
import type { Database } from "../../../helpers/format-databases";
import { apiRequestWrapper } from "../../../helpers/http-client";
import PlusIcon from "../../icons/PlusIcon";
import Navbar from "../../parts/Navbar/Navbar";
import OurDataView from "../../utils/OurDataView/OurDataView";
import SearchInput from "../../utils/SearchInput/SearchInput";
import DatabaseCard from "./DatabaseCard/DatabaseCard";
import "./DatabaseListScreen.scss";

export default function DatabaseListScreen() {
  const wrapAsync = useWrapAsync();
  const [total, setTotal] = useState<number>();
  const [data, setData] = useState<Database[]>();
  const [searchValue, setSearchValue] = useState("");

  const loadData = async (options: {
    limit: number;
    page: number;
  }) => {
    if (searchValue) {
      await handleSearchDatabase();
      return;
    }
    await handleFetchDatabases(options);
  };
  const handleSearchDatabase = async () => {
    await apiRequestWrapper(async () => {
      const result = await searchDatabase(searchValue);
      setData(result.data);
      setTotal(result.pagination?.total ?? result.data.length);
    });
  };
  const handleFetchDatabases = async (options: {
    limit: number;
    page: number;
  }) => {
    await apiRequestWrapper(async () => {
      const result = await fetchDatabases(options);
      setData(result.data);
      setTotal(result.pagination?.total ?? result.data.length);
    });
  };

  return (
    <div>
      <Navbar pageTitle="Liste des bases de données" />

      <div className="DatabaseListScreen Container">
        <div className="Container-content">
          <div className="DatabaseListScreen-header">
            <SearchInput
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              onSearch={wrapAsync(handleSearchDatabase)}
              onClear={wrapAsync(() =>
                handleFetchDatabases({
                  limit: 10,
                  page: 1,
                }),
              )}
            />

            <Link to="/databases/create" className="Btn primary icon link">
              Nouveau <PlusIcon />
            </Link>
          </div>

          <OurDataView<Database>
            data={data}
            total={total}
            loadData={loadData}
            itemTemplate={(database) => (
              <DatabaseCard
                key={database.id}
                route={`/databases/${database.id}/view`}
                label={database.name}
                fields={database.schemaNames.length}
                rows={database.lineNumber}
                date={dayJsFormat(database.createdAt, "DD/MM/YYYY")}
                status={database.databaseGenerated ? "validate" : "in-progress"}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
