import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useState } from "react";

import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import type { Nullable } from "primereact/ts-helpers";
import { syncOpenModalDialog } from "../../../../global-tools/dialog-system";
import { showToast } from "../../../../global-tools/toast-system";
import { checkIsTextFieldType } from "../../../../helpers/check-schema-field-type";
import type {
  SchemaField,
  SchemaFieldOption,
  SchemaFieldTypes,
} from "../../../../helpers/format-databases";
import PlusIcon from "../../../icons/PlusIcon";
import Spinner from "../../../utils/Spinner/Spinner";
import { SelectSchemaFieldType } from "../SelectSchemaFieldType/SelectSchemaFieldType";
import makeUploadFileDialog from "./UploadFileDialog/UploadFileDialog";

import "./DatabaseConfig.scss";

export interface DatabaseConfigProps {
  schemaFieldTypes: SchemaFieldTypes;
  schemaFields: SchemaField[];
  initialValues?: {
    label?: string;
    required?: boolean;
    defaultValue?: string;
    type?: string;
    name?: string;
    fieldLength: Nullable<number | null>;
    options?: SchemaFieldOption[];
  };
  onChange: (fields: SchemaField[]) => void;
}

export default function DatabaseConfig({
  schemaFieldTypes,
  schemaFields,
  initialValues,
  onChange,
}: DatabaseConfigProps) {
  const [label, setLabel] = useState("");
  const [required, setRequired] = useState(true);
  const [defaultValue, setDefaultValue] = useState("");
  const [type, setType] = useState<string>();
  const [name, setName] = useState("");
  const [options, setOptions] = useState<SchemaFieldOption[]>();
  const [fieldLength, setFieldLength] = useState<Nullable<number | null>>();
  const [errors, setErrors] = useState<Map<string, string>>(new Map());

  const addField = () => {
    if (!type) return;
    if (type === "enum" && (!options || options.length === 0)) {
      showToast(`Renseignez les options de la liste de choix '${label}'`, { severity: "warn" });
      return;
    }
    onChange([
      {
        label,
        name,
        type,
        default: defaultValue === "" ? undefined : defaultValue,
        length: fieldLength === null ? undefined : fieldLength,
        options,
        required,
      },
    ]);
    resetFieldStates();
  };

  const initType = () => {
    if (schemaFieldTypes.size === 0) {
      throw new Error("No schema field types");
    }
    const firstKey = schemaFieldTypes.keys().next().value;
    setType(firstKey);
  };
  const resetFieldStates = () => {
    setLabel("");
    initType();
    setRequired(true);
    setDefaultValue("");
    setName("");
    setFieldLength(undefined);
    setOptions(undefined);
  };
  const onNameChange = () => {
    const result = schemaFields.find((f) => f.name === name);
    const oldErrors = new Map(errors);
    if (result) {
      oldErrors.set("name", "Il existe déjà un champ avec ce nom");
      setErrors(oldErrors);
    } else {
      oldErrors.delete("name");
      setErrors(oldErrors);
    }
  };

  useEffect(onNameChange, [name]);
  useEffect(() => {
    if (!initialValues) return;
    if (initialValues.type) setType(initialValues.type);
    if (initialValues.defaultValue) setDefaultValue(initialValues.defaultValue);
    if (initialValues.fieldLength) setFieldLength(initialValues.fieldLength);
    if (initialValues.label) setLabel(initialValues.label);
    if (initialValues.name) setName(initialValues.name);
    if (initialValues.options) setOptions([...initialValues.options]);
    if (initialValues.required) setRequired(initialValues.required);
  }, [initialValues]);
  useEffect(initType, []);

  if (!type) return <Spinner />;

  return (
    <div className="DatabaseConfig Card mt-6">
      <h2 className="DatabaseConfig-title">Configuration de la base de données</h2>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          addField();
        }}
        className="DatabaseConfig-form"
      >
        <div className="DatabaseConfig-inputGroup">
          <label className="Field">
            <span className="Field-label">Libellé du champ</span>
            <InputText
              className="Field-input w-full"
              placeholder="Champ requis *"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
          </label>

          <div className="DatabaseConfig-required w-full">
            <label className="Field">
              <span className="Field-label">Est obligatoire?</span>
              <div className="Field-radioButtonContainer">
                <label className="RadioButton">
                  <RadioButton
                    className="RadioButton-input"
                    name="yes"
                    value={true}
                    onChange={(e) => setRequired(e.value)}
                    checked={required}
                  />
                  <span className="RadioButton-label">Oui</span>
                </label>
                <label className="RadioButton">
                  <RadioButton
                    className="RadioButton-input"
                    name="no"
                    value={false}
                    onChange={(e) => setRequired(e.value)}
                    checked={!required}
                  />
                  <span className="RadioButton-label">Non</span>
                </label>
              </div>
            </label>
          </div>

          <label className="Field">
            <span className="Field-label">Valeur par défaut</span>
            <InputText
              className="Field-input w-full"
              value={defaultValue}
              onChange={(e) => setDefaultValue(e.target.value)}
            />
          </label>
        </div>

        <div className="DatabaseConfig-doubleInput">
          <label className="Field w-full">
            <span className="Field-label">Nom du champ</span>
            <InputText
              className="Field-input w-full"
              placeholder="Champ requis *"
              value={name}
              onChange={(e) => setName(e.target.value.toLowerCase())}
              required
              invalid={!!errors.get("name")}
            />
            <small className="Field-errorMessage">{errors.get("name")}</small>
          </label>

          {checkIsTextFieldType(type) && (
            <label className="Field w-full">
              <span className="Field-label">Longueur du champ</span>
              <InputNumber
                className="Field-inputNumber w-full"
                value={fieldLength}
                onValueChange={(e) => setFieldLength(e.value)}
              />
            </label>
          )}
        </div>

        <SelectSchemaFieldType
          schemaFieldTypes={schemaFieldTypes}
          type={type}
          fieldLength={fieldLength}
          options={options}
          onChange={(val) => {
            setOptions(val.options);
            setType(val.type);
            setFieldLength(val.fieldLength);
          }}
        />

        <div className="DatabaseConfig-footer">
          <Button
            type="submit"
            className="Btn primary icon"
            label="Ajouter le champ"
            icon={<PlusIcon />}
            disabled={errors.size > 0}
          />
          <span style={{ color: "#BABABA" }}>ou</span>
          <Button
            label="Uploader un fichier"
            className="Btn success icon"
            icon="pi pi-save"
            onClick={() =>
              syncOpenModalDialog(makeUploadFileDialog, {
                onValidate: onChange,
                schemaFieldTypes,
              })
            }
          />
        </div>
      </form>
    </div>
  );
}
