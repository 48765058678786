import type { Database } from "./format-databases";
import type { Reference } from "./format-references";

/**
 * @param databases
 * @returns Reference[]
 * @description returns references which are databases with databaseGenerated at true and
 * schema defined and not empty
 */
export function getReferences(databases: Database[]): Reference[] {
  const references: Reference[] = [];
  for (const database of databases) {
    const databaseGenerated = database.databaseGenerated;
    if (!databaseGenerated) continue;
    const schema = database.schema;
    if (!schema || schema.length === 0) {
      throw new Error("Generated database should have schema fields");
    }
    references.push({
      ...database,
      schema,
      databaseGenerated,
    });
  }
  return references;
}

export function getReference(database: Database): Reference {
  const databaseGenerated = database.databaseGenerated;
  const schema = database.schema;
  if (!schema || schema.length === 0 || !databaseGenerated) {
    throw new Error(
      `Reference with id '${database.id}' should be generated and have schema fields`,
    );
  }
  return {
    ...database,
    schema,
    databaseGenerated,
  };
}
