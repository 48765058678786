import { useWrapAsync } from "@paroicms/front-app-log";
import { useEffect } from "react";
import { fetchDatabases } from "../../../api-requests/databases";
import { getReferences } from "../../../helpers/get-references";
import { apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import Navbar from "../../parts/Navbar/Navbar";
import Spinner from "../../utils/Spinner/Spinner";
import DefineDefaultReference from "./DefineDefaultReference/DefineDefaultReference";
import DefineKpi from "./DefineKpi/DefineKpi";

export default function SettingsScreen() {
  const wrapAsync = useWrapAsync();
  const references = useAppStore((state) => state.references);
  const setReferences = useAppStore((state) => state.setReferences);

  useEffect(
    wrapAsync(async () => {
      if (references === undefined) {
        await apiRequestWrapper(async () => {
          const result = await fetchDatabases({ limit: 200, page: 1 });
          setReferences(getReferences(result.data));
        });
      }
    }),
    [references],
  );

  if (!references) return <Spinner />;
  return (
    <div className="SettingsScreen">
      <Navbar pageTitle="Configuration" />
      <div className="Container">
        <div className="Container-content">
          <DefineDefaultReference references={references} />
          <DefineKpi className="mt-4" references={references} />
        </div>
      </div>
    </div>
  );
}
