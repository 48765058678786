import { useAppStore } from "./app.store";

export function useAuthenticatedUser() {
  const authUser = useAppStore((state) => state.authUser);
  if (!authUser) throw new Error("No authenticated user");
  return authUser;
}

export function useRefreshReferencesNReferenceData(referenceId: number) {
  const setReferences = useAppStore((state) => state.setReferences);
  const referenceDataById = useAppStore((state) => state.referenceDataById);
  const setReferenceDataById = useAppStore((state) => state.setReferenceDataById);

  return () => {
    const oldReferenceDataById = new Map(referenceDataById);
    oldReferenceDataById.set(referenceId, undefined);
    setReferenceDataById(oldReferenceDataById);
    setReferences(undefined);
  };
}
