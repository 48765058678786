import { Button } from "primereact/button";
import {
  FileUpload,
  type FileUploadFile,
  type FileUploadHandlerEvent,
  type FileUploadHeaderTemplateOptions,
  type ItemTemplateOptions,
} from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { useRef, useState } from "react";
import { appLog } from "../../../context";
import { showToast } from "../../../global-tools/toast-system";
import { createAcceptTypeFilter } from "../../../helpers/accept-type-filter";
import { ClientError } from "../../../helpers/http-client";

export default function OurFileUpload({
  onFileChange,
  onFileRemove,
}: {
  onFileChange(file: FileUploadFile): Promise<void> | void;
  onFileRemove(): void;
}) {
  const limitSize = 10_000_000; // 10 Mo
  const accept =
    "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);

  const handleOnFileChange = async (event: FileUploadHandlerEvent) => {
    const file = event.files[0];
    const acceptTypeFilter = createAcceptTypeFilter(accept);
    if (!acceptTypeFilter(file.type)) {
      fileUploadRef.current?.clear();
      showToast("Fichier invalide", {
        severity: "warn",
      });
      return;
    }

    setTotalSize(file.size);
    try {
      await onFileChange(file);
      showToast("Fichier Uploadé", { severity: "success" });
    } catch (error) {
      appLog.error(error);
      if (error instanceof ClientError) {
        showToast(error.message, {
          severity: "error",
          sticky: true,
        });
      } else {
        showToast("Fichier vide ou non valide", { severity: "error", sticky: true });
      }
    }
  };
  const onTemplateRemove = (file: File, callback: () => void) => {
    setTotalSize(totalSize - file.size);
    callback();
    onFileRemove();
  };
  const onTemplateClear = () => {
    setTotalSize(0);
    onFileRemove();
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    const progressBarVal = (totalSize * 100) / limitSize;
    const formatedValue = fileUploadRef?.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>
            {formatedValue} / {limitSize / 1_000_000} Mo
          </span>
          <ProgressBar
            value={progressBarVal}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          />
        </div>
      </div>
    );
  };
  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img alt={file.name} role="presentation" src={(file as any).objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          style={{ width: "30px", height: "10px" }}
          onClick={(e) => onTemplateRemove(file, () => props.onRemove(e))}
        />
      </div>
    );
  };
  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        />
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Glissez-déposez votre fichier ici
        </span>
      </div>
    );
  };
  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  return (
    <FileUpload
      ref={fileUploadRef}
      onError={onTemplateClear}
      onClear={onTemplateClear}
      headerTemplate={headerTemplate}
      itemTemplate={itemTemplate}
      emptyTemplate={emptyTemplate}
      chooseOptions={chooseOptions}
      auto
      accept={accept}
      maxFileSize={limitSize}
      customUpload
      uploadHandler={handleOnFileChange}
      chooseLabel="Choisir un fichier"
    />
  );
}
