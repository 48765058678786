import { Button } from "primereact/button";
import { useRef, useState } from "react";
import {
  type ReferenceDatumInput,
  type ReferenceDatumInputVal,
  createReferenceDatum,
} from "../../../../api-requests/reference-data";
import { appLog } from "../../../../context";
import { syncOpenModalDialog } from "../../../../global-tools/dialog-system";
import { showToast } from "../../../../global-tools/toast-system";
import type { SchemaField } from "../../../../helpers/format-databases";
import { transformToFormDataIfFileField } from "../../../../helpers/transform-to-form-data";
import makeUploadReferenceDataDialog, {
  type UploadReferenceDataDialogParameter,
} from "../../../utils/ReferenceDataDataTable/UploadReferenceDataDialog";
import type { FileInputHandle } from "../../../utils/SchemaFieldTypeInputs/FileInput/FileInput";
import SchemaFieldInput from "../../../utils/SchemaFieldTypeInputs/SchemaFieldInput";

import { useRefreshReferencesNReferenceData } from "../../../../store/store-hooks";
import "./CreateReferenceDatum.scss";

export default function CreateReferenceDatum({
  referenceId,
  referenceSchema,
  className,
}: {
  referenceId: number;
  referenceSchema: SchemaField[];
  className?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<ReferenceDatumInput>(new Map());
  const [errorStatus, setErrorStatus] = useState<Map<string, boolean>>(new Map());
  const refresh = useRefreshReferencesNReferenceData(referenceId);
  const fileInputRef = useRef<FileInputHandle>(null);

  const onChange = ({ name, value }: { name: string; value?: ReferenceDatumInputVal }) => {
    const oldValues: ReferenceDatumInput = new Map(inputValues);
    oldValues.set(name, value);
    setInputValues(oldValues);
  };
  const handleReferenceDatumCreation = async () => {
    if (fileInputRef.current && !fileInputRef.current.checkInput()) return;
    if (inputValues.size === 0) return;
    setLoading(true);
    const values = transformToFormDataIfFileField(inputValues, referenceSchema);
    try {
      await createReferenceDatum(referenceId, values);
      setInputValues(new Map());
      showToast("Donnée ajoutée", { severity: "success" });
      refresh();
    } catch (error) {
      appLog.error(error);
      showToast("Echec ajout de donnée", { severity: "error", sticky: true });
    }
    setLoading(false);
  };
  const disabledButton = () => {
    for (const [, status] of errorStatus) {
      if (status) {
        return true;
      }
    }
    return false;
  };

  return (
    <form
      className={`CreateReferenceDatum Card${className ? ` ${className}` : ""}`}
      onSubmit={(e) => {
        e.preventDefault();
        handleReferenceDatumCreation();
      }}
    >
      <div className="CreateReferenceDatum-body">
        {referenceSchema.map((field) => (
          <SchemaFieldInput
            key={field.name}
            field={field}
            value={inputValues.get(field.name)}
            forwardedRef={fileInputRef}
            onChange={onChange}
            onError={(val) => {
              const oldErrorStatus = new Map(errorStatus);
              oldErrorStatus.set(field.name, val);
              setErrorStatus(oldErrorStatus);
            }}
          />
        ))}
      </div>

      <div className="CreateReferenceDatum-footer">
        <Button
          type="submit"
          className="Btn primary"
          label="Enregistrer"
          disabled={disabledButton()}
          loading={loading}
        />
        <span style={{ color: "#BABABA" }}>ou</span>
        <Button
          className="Btn success icon"
          label="Importer des données"
          icon="pi pi-save"
          onClick={(ev) => {
            ev.preventDefault();
            syncOpenModalDialog<UploadReferenceDataDialogParameter>(makeUploadReferenceDataDialog, {
              referenceId,
            });
          }}
        />
      </div>
    </form>
  );
}
