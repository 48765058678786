import { strValOrUndef } from "@paroi/data-formatters-lib";
import type { Obj } from "@paroicms/anywhere-lib";
import { parse } from "papaparse";
import { Button } from "primereact/button";
import type { FileUploadFile } from "primereact/fileupload";
import { Suspense, lazy, useState } from "react";
import * as XLSX from "xlsx";
import type { ModalDialogProps } from "../../../../../global-tools/dialog-system";
import type { SchemaField, SchemaFieldTypes } from "../../../../../helpers/format-databases";
import OurFileUpload from "../../../../utils/OurFileUpload/OurFileUpload";
import Spinner from "../../../../utils/Spinner/Spinner";

import "./UploadFileDialog.scss";

const SchemaFieldDataTable = lazy(() => import("../../SchemaFieldDataTable/SchemaFieldDataTable"));

export interface UploadFileDialogProps extends ModalDialogProps<void> {
  closeDialog: () => void;
  parameter: UploadFileDialogParameter;
}

export interface UploadFileDialogParameter {
  onValidate(values: SchemaField[]): void;
  schemaFieldTypes: SchemaFieldTypes;
}

export default function makeUploadFileDialog({
  closeDialog,
  parameter: { onValidate, schemaFieldTypes },
}: UploadFileDialogProps) {
  const [fields, setFields] = useState<SchemaField[]>([]);

  const addFields = () => {
    if (fields.length === 0) return;
    onValidate(fields);
    closeDialog();
  };
  const handleOnFileChange = async (file: FileUploadFile) => {
    let parsedFields: SchemaField[];
    if (file.type === "text/csv") {
      parsedFields = await parseCsvFile(file, schemaFieldTypes);
    } else parsedFields = await parseExcelFile(file, schemaFieldTypes);
    setFields([...parsedFields]);
  };

  const content = (
    <div className="m-0 UploadFileDialog-content">
      <OurFileUpload
        onFileChange={handleOnFileChange}
        onFileRemove={() => setFields([])}
      />

      <Suspense fallback={<Spinner />}>
        <SchemaFieldDataTable
          className="mt-4"
          schemaFields={fields}
          schemaFieldTypes={schemaFieldTypes}
        />
      </Suspense>

      <div className="mt-6 flex justify-content-between">
        <Button className="Btn secondary" label="Annuler" onClick={closeDialog} />
        <Button
          className="Btn primary"
          label="Valider"
          onClick={addFields}
          disabled={fields.length === 0}
        />
      </div>
    </div>
  );

  return {
    header: (
      <div className="text-center underline" style={{ fontSize: "22px", fontWeight: "400" }}>
        Uploader un fichier CSV ou Excel
      </div>
    ),
    content,
  };
}

function parseExcelFile(file: File, schemaFieldTypes: SchemaFieldTypes): Promise<SchemaField[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const csvString = XLSX.utils.sheet_to_csv(worksheet);
      const result = await parseCsvFile(csvString, schemaFieldTypes);
      resolve(result);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

function parseCsvFile(
  file: File | string,
  schemaFieldTypes: SchemaFieldTypes,
): Promise<SchemaField[]> {
  return new Promise((resolve, reject) => {
    parse(file, {
      header: true,
      skipEmptyLines: true,
      error: (error) => reject(error),
      complete: (results) => {
        const defaultSchemaType = schemaFieldTypes.keys().next().value;
        if (!defaultSchemaType) {
          throw new Error("Empty schema field types");
        }
        const fields: Map<string, SchemaField> = new Map();
        const data = results.data;
        const dataFields = results.meta.fields;
        if (data.length === 0 && (!dataFields || dataFields.length === 0)) {
          reject("Fichier vide ou non valide");
          return;
        }
        if (data.length > 0) {
          const row = data[0] as Obj;
          for (const [columnName, type] of Object.entries(row)) {
            const formatedType = strValOrUndef(type);
            const normalizedStr = normalizeStr(columnName);
            if (!isValidColumnName(normalizedStr) || fields.has(normalizedStr)) {
              continue;
            }
            fields.set(normalizedStr, {
              label: columnName,
              name: normalizedStr,
              required: false,
              type:
                formatedType && isValidSchemaFieldType(formatedType, schemaFieldTypes)
                  ? formatedType
                  : schemaFieldTypes.has("string")
                    ? "string"
                    : defaultSchemaType,
            });
          }
        }

        if (dataFields && data.length < dataFields.length) {
          for (let i = 0; i < dataFields.length; ++i) {
            const field = dataFields[i];
            if (i < data.length) continue;
            const normalizedStr = normalizeStr(field);
            if (!isValidColumnName(normalizedStr) || fields.has(normalizedStr)) {
              continue;
            }
            fields.set(normalizedStr, {
              label: field,
              name: normalizedStr,
              required: false,
              type: schemaFieldTypes.has("string") ? "string" : defaultSchemaType,
            });
          }
        }

        resolve(Array.from(fields, ([, value]) => value));
      },
    });
  });
}

function isValidSchemaFieldType(type: string, schemaFieldTypes: SchemaFieldTypes): boolean {
  if (schemaFieldTypes.has(type)) return true;
  return false;
}

function normalizeStr(str: string): string {
  return (
    str
      .trim()
      .normalize("NFD")
      // biome-ignore lint/suspicious/noMisleadingCharacterClass: not sure
      .replace(/[\u0300-\u036f]/g, "") // remove diacritics
      .replace(/[^0-9a-zA-Z]/g, "")
      .toLowerCase()
  );
}

function isValidColumnName(field: string): boolean {
  const result = field.match(/^[a-zA-Z]+[0-9]*$/);
  return !!result;
}
