import { listVal } from "@paroi/data-formatters-lib";
import { type User, formatUser } from "../helpers/format-users";
import {
  type ApiRequestResponse,
  type PaginationOptions,
  fetchQuery,
} from "../helpers/http-client";

export async function initialFetchAuthUser(token: string): Promise<User> {
  const res = await fetchQuery("me", {
    token,
  });
  return formatUser(res.data);
}

export async function fetchAuthUser(): Promise<User> {
  const res = await fetchQuery("me");
  return formatUser(res.data);
}

export async function fetchUsers({
  limit,
  page,
}: PaginationOptions): Promise<ApiRequestResponse<User[]>> {
  const { data, pagination } = await fetchQuery(`users?limit=${limit}&page=${page}`);
  return {
    data: listVal(data, formatUser),
    pagination,
  };
}

export interface CreateUserInput {
  lastname: string;
  firstname: string;
  email: string;
  roleId: number;
  address?: string;
  phone: string;
  password: string;
}

export async function createUser(body: CreateUserInput): Promise<void> {
  await fetchQuery("users", {
    method: "POST",
    body: JSON.stringify({
      ...body,
      role_id: body.roleId,
    }),
  });
}

export interface EditUserInput {
  lastname: string;
  firstname: string;
  email?: string;
  roleId: number;
  address?: string;
  phone: string;
  password?: string;
}

export async function editUser(id: number, body: EditUserInput): Promise<void> {
  await fetchQuery(`users/${id}`, {
    method: "PUT",
    body: JSON.stringify({
      ...body,
      role_id: body.roleId,
    }),
  });
}

export async function deleteUser(id: number): Promise<void> {
  await fetchQuery(`users/${id}`, {
    method: "DELETE",
  });
}
