import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import GovLogo from "../../../assets/images/gov-logo-sidebar.png";
import { syncOpenModalDialog } from "../../../global-tools/dialog-system";
import { forgetSession } from "../../../global-tools/session-storage";
import { useAppStore } from "../../../store/app.store";
import { useAuthenticatedUser } from "../../../store/store-hooks";
import DatabaseIcon from "../../icons/DatabaseIcon";
import DisconnectIcon from "../../icons/DisconnectIcon";
import HomeIcon from "../../icons/HomeIcon";
import JournalsIcon from "../../icons/JournalsIcon";
import ReferencesIcon from "../../icons/ReferencesIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import StatisticsIcon from "../../icons/StatisticsIcon";
import SupportIcon from "../../icons/SupportIcon";
import makeConfirmDialog from "../../utils/ConfirmDialog/ConfirmDialog";
import "./AppSidebar.scss";

export default function AppSidebar({
  visible,
  setVisible,
  asDrawer,
}: {
  visible: boolean;
  setVisible: (value: boolean) => void;
  asDrawer?: boolean;
}) {
  const isDesktopMode = window.matchMedia("(min-width: 992px)").matches;
  const onRouteChange = isDesktopMode ? undefined : () => setVisible(false);
  const { pathname } = useLocation();
  const setAuthenticated = useAppStore((state) => state.setIsAuthenticated);
  const appLayout = useAppStore((state) => state.appLayout);
  const authUser = useAuthenticatedUser();

  const disconnect = () => {
    forgetSession();
    setAuthenticated(false);
  };

  useEffect(() => {
    if (appLayout === "mobile") setVisible(false);
  }, [location]);

  return (
    <Sidebar
      closeOnEscape={asDrawer}
      visible={visible}
      className="AppSidebar"
      modal={asDrawer}
      dismissable={asDrawer}
      onHide={() => setVisible(false)}
      showCloseIcon={asDrawer}
      header={
        <div className="AppSidebar-header">
          <img
            src={authUser.organization?.logo ? authUser.organization?.logo : GovLogo}
            width="279"
            height="90"
            alt=""
          />
        </div>
      }
    >
      <div className="AppSidebar-content">
        <ul className="AppSidebar-menu">
          <li>
            <SidebarMenuItem
              label="Tableau de bord"
              navigateTo="/"
              icon={<HomeIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
            />
          </li>
          <li>
            <SidebarDropdown
              active={pathname.split("/")[1] === "databases"}
              label="Bases de données"
              icon={<DatabaseIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
              subMenuItems={[
                {
                  label: "Ajouter une base de données",
                  route: "/databases/create",
                },
                {
                  label: "Liste des bases de données",
                  route: "/databases",
                },
              ]}
            />
          </li>

          <li>
            <SidebarDropdown
              active={pathname.includes("references")}
              label="Référentiels"
              icon={<ReferencesIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
              subMenuItems={[
                {
                  label: "Liste des référentiels",
                  route: "/references",
                },
                {
                  label: "Consultation",
                  route: "/references/data",
                },
                {
                  label: "Enregistrement",
                  route: "/references/create-data",
                },
              ]}
            />
          </li>

          <li>
            <SidebarMenuItem
              label="Journals"
              navigateTo="/journals"
              icon={<JournalsIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
            />
          </li>

          <li>
            <SidebarMenuItem
              label="Statistiques"
              navigateTo="/statistics"
              icon={<StatisticsIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
            />
          </li>

          <li>
            <SidebarDropdown
              active={pathname.includes("settings")}
              label="Paramètres"
              icon={<SettingsIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
              subMenuItems={[
                {
                  label: "Gestion des Utilisateurs",
                  route: "/settings/users",
                },
                {
                  label: "Configuration",
                  route: "/settings",
                },
                {
                  label: "Développeur",
                  route: "/settings/developer",
                },
              ]}
            />
          </li>

          <li>
            <SidebarMenuItem
              label="Support"
              navigateTo="/support"
              icon={<SupportIcon classNames="AppSidebar-menuItemIcon" />}
              onRouteChange={onRouteChange}
            />
          </li>

          <li>
            <button
              className="AppSidebar-menuItem danger"
              type="button"
              onClick={() =>
                syncOpenModalDialog(makeConfirmDialog, {
                  onConfirm: disconnect,
                  warningMessage: "Voulez-vous vraiment vous déconnecter?",
                  header: "Confirmation",
                  label: "Se déconnecter",
                })
              }
            >
              <DisconnectIcon classNames="AppSidebar-menuItemIcon" />
              Déconnexion
            </button>
          </li>
        </ul>
      </div>
    </Sidebar>
  );
}

function SidebarMenuItem({
  navigateTo,
  icon,
  label,
  onRouteChange,
}: { label: string; navigateTo: string; icon: JSX.Element; onRouteChange?(): void }) {
  const { pathname } = useLocation();
  const linkActive = navigateTo === "/" ? pathname === navigateTo : pathname.includes(navigateTo);

  return (
    <Link
      to={navigateTo}
      onClick={onRouteChange}
      className={`AppSidebar-menuItem ${linkActive ? "active" : ""}`}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
}

interface SubMenuItem {
  route: string;
  label: string;
}

function SidebarDropdown({
  label,
  active,
  subMenuItems,
  icon,
  onRouteChange,
}: {
  label: string;
  active: boolean;
  subMenuItems: SubMenuItem[];
  icon: JSX.Element;
  onRouteChange?(): void;
}) {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!active) {
      setShow(false);
    }
  }, [pathname]);

  return (
    <>
      <button
        type="button"
        className={`AppSidebar-menuItem ${active || show ? "active" : ""} `}
        onClick={() => setShow(!show)}
      >
        {icon}
        {label}
      </button>
      <ul className={`AppSidebar-subMenu ${show ? "visible" : ""}`}>
        {subMenuItems.map((item) => (
          <li key={item.route}>
            <Link to={item.route} onClick={onRouteChange} className="AppSidebar-subMenuItem">
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}
