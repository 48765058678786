import { useWrapAsync } from "@paroicms/front-app-log";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Suspense, lazy, useEffect, useState } from "react";
import { type DatabaseFormInput, fetchSchemaFieldTypes } from "../../../api-requests/databases";
import { appLog } from "../../../context";
import { showToast } from "../../../global-tools/toast-system";
import type { SchemaField } from "../../../helpers/format-databases";
import { ClientError, apiRequestWrapper } from "../../../helpers/http-client";
import { useAppStore } from "../../../store/app.store";
import NoDataMessage from "../../utils/NoDataMessage/NoDataMessage";
import Spinner from "../../utils/Spinner/Spinner";
import DatabaseConfig from "../DatabaseCreateScreen/DatabaseConfig/DatabaseConfig";

const SchemaFieldDataTable = lazy(
  () => import("../DatabaseCreateScreen/SchemaFieldDataTable/SchemaFieldDataTable"),
);

export default function DatabaseForm({
  save,
  saveButtonText,
  initialValues,
  successMessage,
  errorMessage,
  onSave,
}: {
  initialValues?: {
    name?: string;
    description?: string;
    schemaFields?: SchemaField[];
  };
  successMessage: string;
  errorMessage: string;
  save: (values: DatabaseFormInput) => Promise<void>;
  onSave: () => void;
  saveButtonText: string;
}) {
  const wrapAsync = useWrapAsync();
  const setDatabases = useAppStore((state) => state.setDatabases);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [schemaFields, setSchemaFields] = useState<SchemaField[]>([]);
  const schemaFieldTypes = useAppStore((state) => state.schemaFieldTypes);
  const setSchemaFieldTypes = useAppStore((state) => state.setSchemaFieldTypes);

  const handleNameChange = (value: string) => {
    const oldErrors = new Map(errors);
    if (value === "") {
      oldErrors.set("name", "Ce champ est requis");
      setErrors(oldErrors);
    } else {
      oldErrors.delete("name");
      setErrors(oldErrors);
    }
    setName(value);
  };
  const handleSaveAction = wrapAsync(async () => {
    if (schemaFields.length === 0) {
      showToast("Aucun champ renseigné pour la base de données", {
        severity: "warn",
      });
      return;
    }
    for (const field of schemaFields) {
      if (field.type === "enum" && (!field.options || field.options.length === 0)) {
        showToast(`Renseignez les options de la liste de choix '${field.label}'`, {
          severity: "warn",
        });
        return;
      }
    }
    setLoading(true);
    try {
      const data = {
        name,
        description: description === "" ? undefined : description,
        schema: schemaFields.map((f) => ({
          ...f,
          length: f.length === undefined ? null : f.length,
          default: f.default === undefined ? null : f.default,
        })),
      };
      await save(data);
      setDatabases(undefined);
      onSave();
      showToast(successMessage, { severity: "success" });
    } catch (error) {
      appLog.error(error);
      if (error instanceof ClientError) {
        showToast(error.message, {
          severity: "error",
          sticky: true,
        });
      } else {
        showToast(errorMessage, { severity: "error", sticky: true });
      }
    }
    setLoading(false);
  });

  useEffect(() => {
    if (initialValues?.name) handleNameChange(initialValues.name);
    if (initialValues?.description) setDescription(initialValues.description);
    if (initialValues?.schemaFields) setSchemaFields(initialValues.schemaFields);
  }, [initialValues]);
  useEffect(
    wrapAsync(async () => {
      if (schemaFieldTypes !== undefined) return;
      await apiRequestWrapper(async () => {
        setSchemaFieldTypes(await fetchSchemaFieldTypes());
      });
    }),
    [schemaFieldTypes],
  );

  if (!schemaFieldTypes) return <Spinner />;
  if (schemaFieldTypes.size === 0) return <NoDataMessage message="Aucun type de champ défini" />;
  return (
    <div className="DatabaseCreateScreen-form Card">
      <label className="Field">
        <span className="Field-label">Nom de la base de données</span>
        <InputText
          className="Field-input w-full"
          placeholder="Champ requis *"
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          invalid={!!errors.get("name")}
        />
        <small className="Field-errorMessage">{errors.get("name")}</small>
      </label>

      <label className="Field mt-3">
        <span className="Field-label">Description de la base de données</span>
        <InputTextarea
          rows={3}
          className="Field-textarea w-full"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>

      <DatabaseConfig
        schemaFields={schemaFields}
        onChange={(val) => setSchemaFields([...(schemaFields ?? []), ...val])}
        schemaFieldTypes={schemaFieldTypes}
      />

      <Suspense fallback={<Spinner />}>
        <SchemaFieldDataTable
          className="mt-4"
          schemaFields={schemaFields}
          schemaFieldTypes={schemaFieldTypes}
          onChange={(newFields) => setSchemaFields(newFields)}
        />
      </Suspense>

      <div className="DatabaseCreateScreen-footer">
        <Button
          className="Btn lg success"
          label={saveButtonText}
          onClick={handleSaveAction}
          disabled={errors.size > 0 || name === ""}
          loading={loading}
        />
      </div>
    </div>
  );
}
